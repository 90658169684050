<template>
  <v-container fluid>
    <div class="d-flex mb-4">
      <h2>Manage Artworks</h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchText"
        label="Search"
        dense
        hide-details
        outlined
        clearable
      ></v-text-field>
      <v-btn color="success" class="ml-4" @click.native="createArtwork()">
        New Artwork
      </v-btn>
    </div>
    <v-data-table
      v-model="selectedArtworks"
      :search="searchText"
      :items="filteredArtworks"
      :headers="headers"
      @click:row="editArtwork"
      :item-class="rowClass"
      dense
    >
      <template v-slot:[`item.artworkCode`]="{ item }">
        <span style="white-space: nowrap">{{item.artworkCode}}</span>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img
          class="my-2"
          max-height="50px"
          max-width="50px"
          :src="`${imageUrl}/100px/${getImageToDisplay(item.image)}`"
        ></v-img>
      </template>
      <template v-slot:[`item.manageProducts`]="{ item }">
        <!-- <v-btn small color="success" class="mr-4" @click.native="editArtwork(item)">
          Marketing
        </v-btn> -->
        <v-btn small color="success" class="mr-4" @click.stop="editArtworkProducts(item)">
          Add Products
        </v-btn>
      </template>
    </v-data-table>
    <artwork-dialog
      v-if="openDialog"
      v-model="openDialog"
      :data="currentArtwork || null"
      :edit="edit"
      @close="closeDialog"
    />
    <artwork-products-dialog
      v-if="openProductsDialog"
      v-model="openProductsDialog"
      :artwork="currentArtwork"
      @close="closeProductsDialog"
    />
    <artwork-custom-product-dialog
      v-if="openCustomProductDialog"
      v-model="openCustomProductDialog"
      :artwork="currentArtwork"
      @close="closeProductsDialog"
    />
  </v-container>
</template>

<script>
import ArtworkDialog from "./components/ArtworkDialog";
import ArtworkProductsDialog from "./components/ArtworkProductsDialog";
import ArtworkCustomProductDialog from "./components/ArtworkCustomProductDialog";
import ArtworkApi from "@/api/artwork";

export default {
  components: {
    ArtworkDialog,
    ArtworkProductsDialog,
    ArtworkCustomProductDialog
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      searchText: null,
      openDialog: false,
      openProductsDialog: false,
      openCustomProductDialog: false,
      currentArtwork: null,
      artworks: [],
      selectedArtworks: [],
      filteredArtworks: [],
      headers: [
        { text: "seq", value: "skuSequence" },
        { text: "Artist", value: "artist.name" },
        { text: "Title", value: "title" },
        { text: "Code", value: "artworkCode" },
        { text: "Pricing Type", value: "salesModel" },
        { text: "Subject Matter", value: "subjectMatter" },
        { text: "Image", value: "image" },
        { text: "", value: "manageProducts" },
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      uploadSuccess: false,
      edit: false,
      mode: "preview",
    };
  },
  async created() {
    ArtworkApi.loadArtworks()
      .then((results) => {
        this.artworks = results.data;
        this.filteredArtworks = [].concat(this.artworks)
        this.loadingData = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.loadingData = false;
      });
  },
  mounted: function () {},
  computed: {},
  watch: {},
  methods: {
    rowClass(item) {
        return 'clickable';
    },
    createArtwork() {
      this.edit = false
      this.openDialog = true;
    },
    editArtwork(evt) {
      this.edit = true
      this.currentArtwork = {...evt};
      delete this.currentArtwork.artist
      this.openDialog = true;
    },
    async closeDialog(options) {
      if (options && options.edited) {
        this.searchText = options.artworkCode
        await ArtworkApi.loadArtworks()
        .then((results) => {
          this.artworks = results.data;
          this.filteredArtworks = [].concat(this.artworks)
        })
        .catch((err) => {
          console.log("err", err);
          this.loadingData = false;
        });
      }
      this.loadingData = false;
      this.currentArtwork = null;
      this.openDialog = false;
    },
    editArtworkProducts(evt) {
      this.currentArtwork = {...evt};
      switch (evt.salesModel) {
        case "WHOLESALE":
          this.openProductsDialog = true
          break
        case "CUSTOM":
          this.openCustomProductDialog = true
          break
        default:
          this.openProductsDialog = false;
          this.openCustomProductDialog = false

      }
    },
    closeProductsDialog() {
      // this.searchText = this.currentArtwork.artworkCode
      this.currentArtworkCode = null;
      this.openProductsDialog = false;
      this.openCustomProductDialog = false
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .clickable {
  cursor: pointer
}
</style>
